export const checkSvg = (
  <svg
    width='14'
    height='10'
    viewBox='0 0 14 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.54688 4.43732L5.3821 8.07706L12.456 1.36377'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
    />
  </svg>
);
