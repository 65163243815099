export const footerLinks = [
  {
    name: 'Политика конфиденциальности',
    href: '/upload/docs/politics.pdf',
  },
  {
    name: 'Правила акции',
    href: '/upload/docs/rules.pdf',
  },
  {
    name: 'обратная связь',
    href: '#form',
  },
];
