export const links = [
  {
    name: 'Главная',
    to: '/',
  },
  {
    name: 'как участвовать',
    to: '#how',
  },
  {
    name: 'призы',
    to: '#prizes',
  },
  {
    name: 'победители',
    to: '#winners',
  },
  {
    name: 'вопрос-ответ',
    to: '/faq',
  },
];
